export default [
  { value: "30", label: "Health Benefit Plan Coverage " },
  { value: "85", label: "AIDS " },
  { value: "84", label: "Abortion " },
  { value: "64", label: "Acupuncture " },
  { value: "28", label: "Adjunctive Dental Services " },
  { value: "57", label: "Air Transportation " },
  { value: "AJ", label: "Alcoholism " },
  { value: "GY", label: "Allergy " },
  { value: "79", label: "Allergy Testing " },
  { value: "15", label: "Alternate Method Dialysis " },
  { value: "13", label: "Ambulatory Service Center Facility " },
  { value: "7", label: "Anesthesia " },
  { value: "97", label: "Anesthesiologist " },
  { value: "71", label: "Audiology Exam " },
  { value: "10", label: "Blood Charges " },
  { value: "91", label: "Brand Name Prescription Drug " },
  { value: "B2", label: "Brand Name Prescription Drug - Formulary " },
  { value: "B3", label: "Brand Name Prescription Drug - Non-Formulary " },
  { value: "B1", label: "Burn Care " },
  { value: "58", label: "Cabulance " },
  { value: "87", label: "Cancer " },
  { value: "BL", label: "Cardiac " },
  { value: "BG", label: "Cardiac Rehabilitation " },
  { value: "CQ", label: "Case Management " },
  { value: "78", label: "Chemotherapy " },
  { value: "33", label: "Chiropractic " },
  { value: "34", label: "Chiropractic Office Visits " },
  { value: "16", label: "Chronic Renal Disease (CRD) Equipment " },
  { value: "BD", label: "Cognitive Therapy " },
  { value: "3", label: "Consultation " },
  { value: "C1", label: "Coronary Care " },
  { value: "BC", label: "Day Care (Psychiatric) " },
  { value: "37", label: "Dental Accident " },
  { value: "35", label: "Dental Care " },
  { value: "36", label: "Dental Crowns " },
  { value: "DG", label: "Dermatology " },
  { value: "DS", label: "Diabetic Supplies " },
  { value: "23", label: "Diagnostic Dental " },
  { value: "5", label: "Diagnostic Lab " },
  { value: "73", label: "Diagnostic Medical " },
  { value: "4", label: "Diagnostic X-Ray " },
  { value: "76", label: "Dialysis " },
  { value: "63", label: "Donor Procedures " },
  { value: "AK", label: "Drug Addiction " },
  { value: "DM", label: "Durable Medical Equipment " },
  { value: "12", label: "Durable Medical Equipment Purchase " },
  { value: "18", label: "Durable Medical Equipment Rental " },
  { value: "86", label: "Emergency Services " },
  { value: "BP", label: "Endocrine " },
  { value: "26", label: "Endodontics " },
  { value: "AR", label: "Experimental Drug Therapy " },
  { value: "BR", label: "Eye " },
  { value: "CP", label: "Eyewear and Eyewear Accessories " },
  { value: "82", label: "Family Planning " },
  { value: "CO", label: "Flu Vaccination " },
  { value: "AM", label: "Frames " },
  { value: "89", label: "Free Standing Prescription Drug " },
  { value: "BN", label: "Gastrointestinal " },
  { value: "60", label: "General Benefits " },
  { value: "92", label: "Generic Prescription Drug " },
  { value: "GF", label: "Generic Prescription Drug - Formulary " },
  { value: "GN", label: "Generic Prescription Drug - Non-Formulary " },
  { value: "BT", label: "Gynecological " },
  { value: "30", label: "Health Benefit Plan Coverage" },
  { value: "42", label: "Home Health Care " },
  { value: "43", label: "Home Health Prescriptions " },
  { value: "44", label: "Home Health Visits " },
  { value: "45", label: "Hospice " },
  { value: "47", label: "Hospital " },
  { value: "53", label: "Hospital - Ambulatory Surgical " },
  { value: "51", label: "Hospital - Emergency Accident " },
  { value: "52", label: "Hospital - Emergency Medical " },
  { value: "48", label: "Hospital - Inpatient " },
  { value: "50", label: "Hospital - Outpatient " },
  { value: "49", label: "Hospital - Room and Board " },
  { value: "80", label: "Immunizations " },
  { value: "61", label: "In-vitro Fertilization " },
  { value: "BA", label: "Independent Medical Evaluation " },
  { value: "83", label: "Infertility " },
  { value: "72", label: "Inhalation Therapy " },
  { value: "IC", label: "Intensive Care " },
  { value: "BS", label: "Invasive Procedures " },
  { value: "AO", label: "Lenses " },
  { value: "59", label: "Licensed Ambulance " },
  { value: "54", label: "Long Term Care " },
  { value: "BM", label: "Lymphatic " },
  { value: "62", label: "MRI/CAT Scan " },
  { value: "90", label: "Mail Order Prescription Drug " },
  { value: "BW", label: "Mail Order Prescription Drug: Brand Name " },
  { value: "BX", label: "Mail Order Prescription Drug: Generic " },
  { value: "55", label: "Major Medical " },
  { value: "CM", label: "Mammogram, High Risk Patient " },
  { value: "CN", label: "Mammogram, Low Risk Patient " },
  { value: "BE", label: "Massage Therapy " },
  { value: "69", label: "Maternity " },
  { value: "27", label: "Maxillofacial Prosthetics " },
  { value: "1", label: "Medical Care " },
  { value: "56", label: "Medically Related Transportation " },
  { value: "MH", label: "Mental Health " },
  { value: "CG", label: "Mental Health Facility - Inpatient " },
  { value: "CH", label: "Mental Health Facility - Outpatient " },
  { value: "CE", label: "Mental Health Provider - Inpatient " },
  { value: "CF", label: "Mental Health Provider - Outpatient " },
  { value: "NI", label: "Neonatal Intensive Care " },
  { value: "BQ", label: "Neurology " },
  { value: "65", label: "Newborn Care " },
  { value: "AQ", label: "Nonmedically Necessary Physical " },
  { value: "BI", label: "Nursery " },
  { value: "BU", label: "Obstetrical " },
  { value: "BV", label: "Obstetrical/Gynecological " },
  { value: "AD", label: "Occupational Therapy " },
  { value: "ON", label: "Oncology " },
  { value: "40", label: "Oral Surgery " },
  { value: "38", label: "Orthodontics " },
  { value: "BK", label: "Orthopedic " },
  { value: "9", label: "Other Medical " },
  { value: "77", label: "Otological Exam " },
  { value: "BB", label: "Partial Hospitalization (Psychiatric) " },
  { value: "66", label: "Pathology " },
  { value: "BH", label: "Pediatric " },
  { value: "24", label: "Periodontics " },
  { value: "88", label: "Pharmacy " },
  { value: "AE", label: "Physical Medicine " },
  { value: "PT", label: "Physical Therapy " },
  { value: "BY", label: "Physician Visit - Office: Sick " },
  { value: "BZ", label: "Physician Visit - Office: Well " },
  { value: "32", label: "Plan Waiting Period " },
  { value: "19", label: "Pneumonia Vaccine " },
  { value: "93", label: "Podiatry " },
  { value: "95", label: "Podiatry - Nursing Home Visits " },
  { value: "94", label: "Podiatry - Office Visits " },
  { value: "17", label: "Pre-Admission Testing " },
  { value: "74", label: "Private Duty Nursing " },
  { value: "CB", label: "Private Duty Nursing - Home " },
  { value: "CA", label: "Private Duty Nursing - Inpatient " },
  { value: "96", label: "Professional (Physician) " },
  { value: "A3", label: "Professional (Physician) Visit - Home " },
  { value: "99", label: "Professional (Physician) Visit - Inpatient " },
  { value: "A1", label: "Professional (Physician) Visit - Nursing Home " },
  { value: "98", label: "Professional (Physician) Visit - Office " },
  { value: "A0", label: "Professional (Physician) Visit - Outpatient " },
  {
    value: "A2",
    label: "Professional (Physician) Visit - Skilled Nursing Facility ",
  },
  { value: "75", label: "Prosthetic Device " },
  { value: "39", label: "Prosthodontics " },
  { value: "A4", label: "Psychiatric " },
  { value: "A7", label: "Psychiatric - Inpatient " },
  { value: "A8", label: "Psychiatric - Outpatient " },
  { value: "A5", label: "Psychiatric - Room and Board " },
  { value: "A6", label: "Psychotherapy " },
  { value: "PU", label: "Pulmonary " },
  { value: "BF", label: "Pulmonary Rehabilitation " },
  { value: "6", label: "Radiation Therapy " },
  { value: "A9", label: "Rehabilitation " },
  { value: "AB", label: "Rehabilitation - Inpatient " },
  { value: "AC", label: "Rehabilitation - Outpatient " },
  { value: "AA", label: "Rehabilitation - Room and Board " },
  { value: "RN", label: "Renal " },
  { value: "14", label: "Renal Supplies in the Home " },
  { value: "RT", label: "Residential Psychiatric Treatment " },
  { value: "46", label: "Respite Care " },
  { value: "25", label: "Restorative " },
  { value: "41", label: "Routine (Preventive) Dental " },
  { value: "AN", label: "Routine Exam " },
  { value: "81", label: "Routine Physical " },
  { value: "CK", label: "Screening X-ray " },
  { value: "CL", label: "Screening laboratory " },
  { value: "20", label: "Second Surgical Opinion " },
  { value: "AG", label: "Skilled Nursing Care " },
  { value: "AH", label: "Skilled Nursing Care - Room and Board " },
  { value: "BJ", label: "Skin " },
  { value: "67", label: "Smoking Cessation " },
  { value: "22", label: "Social Work " },
  { value: "AF", label: "Speech Therapy " },
  { value: "AI", label: "Substance Abuse " },
  { value: "CI", label: "Substance Abuse Facility - Inpatient " },
  { value: "CJ", label: "Substance Abuse Facility - Outpatient " },
  { value: "2", label: "Surgical " },
  { value: "8", label: "Surgical Assistance " },
  { value: "CD", label: "Surgical Benefits - Facility " },
  { value: "CC", label: "Surgical Benefits - Professional (Physician) " },
  { value: "21", label: "Third Surgical Opinion " },
  { value: "TC", label: "Transitional Care " },
  { value: "TN", label: "Transitional Nursery Care " },
  { value: "70", label: "Transplants " },
  { value: "UC", label: "Urgent Care " },
  { value: "11", label: "Used Durable Medical Equipment " },
  { value: "AL", label: "Vision (Optometry) " },
  { value: "68", label: "Well Baby Care " },
];
