export default [
  { value: "13162", label: "1199 National Benefit Fund" },
  { value: "36273", label: "AARP" },
  { value: "68055", label: "Absolute Total Care" },
  { value: "22384", label: "Administrative Concepts Inc" },
  { value: "25152", label: "Advantra Freedom" },
  { value: "60054", label: "Aetna" },
  { value: "57604", label: "Aetna Affordable Health Choices " },
  { value: "128CA", label: "Aetna Better Health of California" },
  { value: "128FL", label: "Aetna Better Health of Florida" },
  { value: "26337", label: "Aetna Better Health of Illinois" },
  { value: "68024", label: "Aetna Better Health of Illinois/ IlliniCare" },
  { value: "128KS", label: "Aetna Better Health of Kansas" },
  { value: "128KY", label: "Aetna Better Health of Kentucky" },
  { value: "128LA", label: "Aetna Better Health of Louisiana" },
  { value: "128MD", label: "Aetna Better Health of Maryland" },
  { value: "128MI", label: "Aetna Better Health of Michigan" },
  { value: "128NV", label: "Aetna Better Health of Nevada" },
  { value: "46320", label: "Aetna Better Health of New Jersey" },
  { value: "34734", label: "Aetna Better Health of New York" },
  { value: "50023", label: "Aetna Better Health of Ohio" },
  { value: "128VA", label: "Aetna Better Health of Virginia" },
  { value: "128WV", label: "Aetna Better Health of West Virginia" },
  { value: "23228", label: "Aetna Better Health Pennsylvania" },
  { value: "38692", label: "Aetna TX Medicaid CHIP" },
  { value: "13334", label: "Affinity Health Plan" },
  { value: "AKMCD", label: "AK Medicaid" },
  { value: "00831", label: "AK Medicare Part B" },
  { value: "02001", label: "AK/OR/ID/WA Medicare Part A" },
  { value: "00510", label: "AL BCBS" },
  { value: "ALMCD", label: "AL Medicaid" },
  { value: "10111", label: "AL Medicare Part A" },
  { value: "10112", label: "AL Medicare Part B" },
  { value: "81400", label: "All Savers Insurance/UnitedHealthcare" },
  { value: "81040", label: "Allegiance Benefit Plan Management, Inc." },
  { value: "37308", label: "Allied Benefit Systems" },
  { value: "54398", label: "ALLINA HEALTH AETNA" },
  { value: "75137", label: "AmeriBen Solutions Inc" },
  { value: "74048", label: "American National Ins Co ANICO" },
  { value: "48055", label: "American Progressive Life and Health" },
  { value: "86047", label: "AmeriChoice NJ, Medicaid, NJ Family Care" },
  { value: "26375", label: "Amerigroup " },
  { value: "77799", label: "AmeriHealth Caritas Delaware" },
  { value: "77003", label: "AmeriHealth Caritas Florida" },
  { value: "27357", label: "AmeriHealth Caritas Louisiana" },
  { value: "22248", label: "AmeriHealth Caritas Pennsylvania" },
  { value: "47009", label: "Ameritas Life Insurance Corp" },
  { value: "ARKBS", label: "AR BCBS" },
  { value: "12023", label: "AR Medicaid" },
  { value: "07101", label: "AR Medicare Part A" },
  { value: "07102", label: "AR Medicare Part B" },
  { value: "06603", label: "ASA GEHA" },
  { value: "93221", label: "Asuris Northwest Health" },
  { value: "61108", label: "Author by Humana" },
  { value: "38259", label: "Automated Benefit Services" },
  { value: "59274", label: "AvMed Inc" },
  { value: "53589", label: "AZ Blue Cross" },
  { value: "AZMCD", label: "AZ Medicaid" },
  { value: "03101", label: "AZ Medicare Part A" },
  { value: "03102", label: "AZ Medicare Part B" },
  { value: "12X42", label: "Banner Health - HealthNet Senior" },
  { value: "66901", label: "Banner UFC ALTCS" },
  { value: "66006", label: "BCBS Medicare Advantage (TX,IL,NM,OK,MT)" },
  { value: "00562", label: "BCBS MN - Blue Plus Medicaid" },
  { value: "43324", label: "Beacon Health Strategies" },
  { value: "25145", label: "Benefit Coordinators Corporation" },
  { value: "MCDIL", label: "Blue Cross Community Options" },
  { value: "52192", label: "Bravo Health" },
  { value: "BRIDG", label: "BridgeSpan" },
  { value: "68054", label: "Bridgeway Arizona" },
  { value: "BRGHT", label: "BRIGHT HEALTHCARE" },
  { value: "47198", label: "CA Blue Cross" },
  { value: "94036", label: "CA Blue Shield" },
  { value: "57016", label: "CA Medicaid" },
  { value: "01102", label: "CA Medicare North" },
  { value: "01192", label: "CA Medicare South" },
  { value: "CALOP", label: "CalOptima Direct" },
  { value: "23045", label: "Capital Blue Cross" },
  { value: "57116", label: "Care 1st Health Plan of Arizona" },
  { value: "66010", label: "Care N Care" },
  { value: "25139", label: "Carelink Health Plan" },
  { value: "CARMO", label: "Caremore" },
  { value: "65031", label: "CarePlus Health Plans Inc" },
  { value: "26160", label: "CareSource" },
  { value: "ARCS1", label: "CARESOURCE ARKANSAS" },
  { value: "INCS1", label: "Caresource Indiana Inc" },
  { value: "KYCS1", label: "CareSource KY" },
  { value: "GACS1", label: "CareSource of Georgia" },
  { value: "WVCS1", label: "CareSource of West Virginia" },
  { value: "31114", label: "CareSource OH" },
  { value: "SX065", label: "CDPHP" },
  { value: "68060", label: "CeltiCare" },
  { value: "95386", label: "Cencal Health" },
  { value: "68048", label: "Cenpatico Arizona" },
  { value: "68068", label: "Cenpatico Behavioral Health" },
  { value: "68050", label: "Cenpatico Georgia" },
  { value: "68052", label: "Cenpatico Indiana" },
  { value: "68047", label: "Cenpatico Kansas" },
  { value: "68051", label: "Cenpatico OHIO" },
  { value: "68059", label: "Cenpatico South Carolina" },
  { value: "68046", label: "Cenpatico Wisconsin" },
  { value: "68069", label: "Centene" },
  { value: "68056", label: "CENTENE ADVANTAGE PLANS" },
  { value: "62218", label: "Central SeniorCare" },
  { value: "36215", label: "Central States Health & Welfare Funds" },
  { value: "84146", label: "CHAMPVA HAC" },
  { value: "62308", label: "CIGNA" },
  { value: "SX071", label: "CIGNA Behavioral Health" },
  { value: "86033", label: "CIGNA Medicare Advantage PFFS" },
  { value: "CLEAR", label: "Clear Health Alliance" },
  { value: "77023", label: "Clover Health" },
  { value: "00050", label: "CO BCBS" },
  { value: "91617", label: "CO Kaiser Foundation Health Plan" },
  { value: "SKCO0", label: "CO Medicaid" },
  { value: "04111", label: "CO Medicare Part A" },
  { value: "04102", label: "CO Medicare Part B" },
  {
    value: "73143",
    label: "Community Care Managed Health Care Plans of Oklahoma",
  },
  { value: "SB613", label: "Community Health Plan Washington" },
  { value: "06105", label: "ConnectiCare Inc" },
  { value: "78375", label: "Connecticare Medicare" },
  { value: "71404", label: "Continental General Insurance Co" },
  { value: "75136", label: "CoreSource Little Rock" },
  { value: "35183", label: "CoreSource OH" },
  { value: "06541", label: "CountyCare" },
  { value: "25133", label: "Coventry Health Care" },
  { value: "25130", label: "Coventry Health Care of Delaware" },
  { value: "25127", label: "Coventry Health Care of Georgia " },
  { value: "25132", label: "Coventry Health Care of Iowa" },
  { value: "25135", label: "Coventry Health Care of Louisiana" },
  { value: "25136", label: "Coventry Health Care of Nebraska" },
  { value: "25149", label: "Coventry Health Care of Nevada" },
  { value: "00019", label: "Cox Health Plan" },
  { value: "00060", label: "CT BCBS" },
  { value: "SKCT0", label: "CT Medicaid" },
  { value: "13102", label: "CT Medicare Part B" },
  { value: "DAK01", label: "Dakotacare" },
  { value: "SB580", label: "DC BCBS" },
  { value: "DCMCD", label: "DC Medicaid" },
  { value: "12202", label: "DC Medicare Part B" },
  { value: "12301", label: "DC/MD Medicare Part A" },
  { value: "00570", label: "DE BCBS" },
  { value: "DEMCD", label: "DE Medicaid" },
  { value: "12102", label: "DE Medicare Part B" },
  { value: "74272", label: "Dell Childrens Health Plan" },
  { value: "84135", label: "Denver Health Medical Plan" },
  { value: "84131", label: "Denver Health Medical Plan Inc " },
  { value: "DEVOT", label: "Devoted Health" },
  { value: "23706", label: "Directors Guild of America Prod" },
  { value: "16003", label: "DME Region A" },
  { value: "17003", label: "DME Region B" },
  { value: "18003", label: "DME Region C" },
  { value: "19003", label: "DME Region D" },
  { value: "81039", label: "EBMS" },
  { value: "31625", label: "ElderPlan Inc" },
  { value: "13551", label: "Emblem Health GHI New York Group Health Inc" },
  { value: "12956", label: "Empower" },
  { value: "20818", label: "Essence Healthcare" },
  { value: "35605", label: "Everence" },
  { value: "EH001", label: "Evry Health" },
  { value: "00805", label: "Excellus BCBS - Central New York" },
  { value: "00804", label: "Excellus BCBS - Rochester Area" },
  { value: "00806", label: "Excellus BCBS - Utica Watertown" },
  { value: "22254", label: "Fallon Community Health Plan" },
  { value: "11315", label: "Fidelis Care New York" },
  { value: "94999", label: "FirstCare" },
  { value: "00590", label: "FL BCBS" },
  { value: "77027", label: "FL Medicaid" },
  { value: "09101", label: "FL Medicare Part A" },
  { value: "09102", label: "FL Medicare Part B" },
  { value: "59322", label: "Florida Health Care Plan" },
  { value: "SB942", label: "Freedom Blue Medicare Advantage " },
  { value: "00601", label: "GA BCBS" },
  { value: "SKGA0", label: "GA Medicaid" },
  { value: "10212", label: "GA Medicare Part B" },
  { value: "44054", label: "GEHA" },
  { value: "37602", label: "Golden Rule Insurance Company" },
  { value: "80705", label: "GreatWest Healthcare" },
  { value: "91121", label: "Group Health Cooperative WA State" },
  { value: "36406", label: "Harmony Health Plan of Illinois" },
  { value: "04271", label: "Harvard Pilgrim Health Care" },
  { value: "38224", label: "Health Alliance Plan of Michigan" },
  { value: "25126", label: "Health America Inc" },
  { value: "95019", label: "Health First Health Plans" },
  { value: "95567", label: "Health Net of California and Oregon" },
  { value: "04286", label: "Health New England" },
  { value: "80142", label: "Health Partners PA" },
  { value: "76342", label: "Health Plan of Nevada" },
  { value: "44273", label: "Health Plans Inc" },
  { value: "HMA01", label: "Healthcare Management Administrators Inc (HMA)" },
  { value: "62179", label: "Healthchoice of AZ" },
  { value: "80141", label: "Healthfirst" },
  { value: "SX009", label: "HealthPartners Minnesota" },
  { value: "38216", label: "HealthPlus of Michigan" },
  { value: "71063", label: "HealthSCOPE Benefits" },
  {
    value: "87815",
    label: "HealthSmart Benefit Solutions, Inc. formerly Wells Fargo TPA",
  },
  { value: "63092", label: "HealthSpring HMO Medicare Choice" },
  { value: "00602", label: "Healthy Blue North Carolina" },
  { value: "00403", label: "Healthy Blue South Carolina" },
  { value: "06014", label: "HHH Medicare CA J6 " },
  { value: "15004", label: "HHH Medicare J15" },
  { value: "01202", label: "HI Medicare Part B" },
  { value: "47181", label: "Highmark BCBSD Health Options Inc" },
  { value: "61101", label: "Humana" },
  { value: "IABLS", label: "IA BCBS" },
  { value: "18049", label: "IA Medicaid" },
  { value: "05101", label: "IA Medicare Part A" },
  { value: "05102", label: "IA Medicare Part B" },
  { value: "SB612", label: "ID Blue Cross" },
  { value: "00611", label: "ID Blue Shield" },
  { value: "SKID0", label: "ID Medicaid" },
  { value: "02202", label: "ID Medicare Part B" },
  { value: "00621", label: "IL BCBS" },
  { value: "IL621", label: "IL Medicaid" },
  { value: "06101", label: "IL Medicare Part A" },
  { value: "00952", label: "IL Medicare Part B" },
  { value: "68066", label: "IlliniCare" },
  { value: "00130", label: "IN BCBS Institutional" },
  { value: "00630", label: "IN BCBS Professional" },
  { value: "INMCD", label: "IN Medicaid" },
  { value: "08101", label: "IN Medicare Part A" },
  { value: "08102", label: "IN Medicare Part B" },
  { value: "54704", label: "Independence Blue Cross Blue Shield" },
  { value: "95308", label: "Independent Health" },
  { value: "IEHP1", label: "Inland Empire Health Plan" },
  { value: "04901", label: "J04911 Medicare Part A" },
  { value: "52189", label: "Johns Hopkins Healthcare" },
  {
    value: "94135",
    label: "Kaiser Foundation Health Plan of Northern CA Region",
  },
  {
    value: "94134",
    label: "Kaiser Foundation Health Plan of Southern CA Region",
  },
  {
    value: "52095",
    label: "Kaiser Foundation Health Plan of the MidAtlantic States Inc",
  },
  { value: "91051", label: "Kaiser Foundation Health Plan Washington" },
  { value: "93079", label: "Kaiser Foundation of the Northwest" },
  { value: "94123", label: "Kaiser Hawaii" },
  { value: "21313", label: "Kaiser Health Plan Inc Georgia " },
  { value: "47171", label: "KC BCBS" },
  { value: "73100", label: "Kempton Company" },
  { value: "37217", label: "Key Benefit Administrators India" },
  { value: "47163", label: "KS BCBS" },
  { value: "KSMCD", label: "KS Medicaid" },
  { value: "05201", label: "KS Medicare Part A" },
  { value: "05202", label: "KS Medicare Part B " },
  { value: "00160", label: "KY BCBS Institutional" },
  { value: "00660", label: "KY BCBS Professional" },
  { value: "KYMCD", label: "KY Medicaid" },
  { value: "15101", label: "KY Medicare Part A" },
  { value: "15102", label: "KY Medicare Part B" },
  { value: "23738", label: "LA BCBS" },
  { value: "LACAR", label: "LA Care Health Plan" },
  { value: "LAMCD", label: "LA Medicaid" },
  { value: "07201", label: "LA Medicare Part A" },
  { value: "07202", label: "LA Medicare Part B" },
  { value: "37316", label: "Leon Medical Center Health Plan" },
  { value: "91049", label: "Lifewise WA" },
  { value: "00200", label: "MA BCBS" },
  { value: "MAMCD", label: "MA Medicaid - MassHealth" },
  { value: "14211", label: "MA Medicare Part A" },
  { value: "14212", label: "MA Medicare Part B" },
  { value: "01260", label: "Magellan Health Services" },
  { value: "11303", label: "Magnacare" },
  { value: "68062", label: "Magnolia" },
  { value: "39186", label: "Managed Health Services Indiana " },
  { value: "39187", label: "Managed Health Services Wisconsin" },
  { value: "52461", label: "March Vision Care Inc" },
  { value: "SB690", label: "MD BCBS" },
  { value: "MDMCD", label: "MD Medicaid" },
  { value: "12302", label: "MD Medicare Part B" },
  { value: "00180", label: "ME BCBS Institutional" },
  { value: "00680", label: "ME BCBS Professional" },
  { value: "MEMCD", label: "ME Medicaid" },
  { value: "14011", label: "ME Medicare Part A" },
  { value: "14112", label: "ME Medicare Part B" },
  { value: "56162", label: "MedCost Inc" },
  { value: "94265", label: "Medica" },
  { value: "71890", label: "Medica Health Plan Solutions" },
  { value: "12422", label: "Medica2" },
  { value: "29076", label: "Medical Mutual" },
  { value: "05901", label: "Medicare J5 National Part A" },
  { value: "12M65", label: "MEDICARE PART A CAHINV" },
  { value: "39190", label: "MedStar Family Choice" },
  { value: "251MS", label: "Medstar SelectMedstar Medicare Choice" },
  { value: "86052", label: "Mercy Care Plan AHCCCS" },
  { value: "13189", label: "Meridian Health Plan" },
  { value: "MHPIL", label: "Meridian Health Plan Illinois Complete" },
  { value: "64157", label: "Meritain Health" },
  { value: "41124", label: "Meritain Health Minneapolis" },
  { value: "13265", label: "Metro Plus Health Plan" },
  { value: "74289", label: "MHNET" },
  { value: "MIBLS", label: "MI BCBS" },
  { value: "MIFEP", label: "MI BCBS FEP" },
  { value: "MIMCD", label: "MI MEDICAID" },
  { value: "08202", label: "MI Medicare Part B" },
  { value: "00720", label: "MN BCBS" },
  { value: "BLRDE", label: "MN BCBS - BlueRide Transportation" },
  { value: "MNMCD", label: "MN Medicaid" },
  { value: "06201", label: "MN Medicare Part A" },
  { value: "06202", label: "MN Medicare Part B " },
  { value: "00241", label: "MO BCBS" },
  { value: "MOMCD", label: "MO Medicaid" },
  { value: "05301", label: "MO Medicare Part A" },
  { value: "05302", label: "MO Medicare Part B" },
  { value: "13350", label: "Moda Health" },
  { value: "38333", label: "Molina Healthcare of CALIFORNIA" },
  { value: "51062", label: "Molina Healthcare of Florida" },
  { value: "61799", label: "Molina Healthcare of Idaho" },
  { value: "38334", label: "Molina Healthcare of MICHIGAN" },
  { value: "09824", label: "Molina Healthcare of New Mexico" },
  { value: "20149", label: "Molina Healthcare of Ohio" },
  { value: "20554", label: "Molina Healthcare of Texas" },
  { value: "38336", label: "Molina Healthcare of Washington" },
  { value: "ABRI1", label: "MOLINA HEALTHCARE Of WI" },
  { value: "SX109", label: "Molina Healthcare Utah" },
  { value: "00230", label: "MS BCBS" },
  { value: "MSMCD", label: "MS Medicaid" },
  { value: "07301", label: "MS Medicare Part A" },
  { value: "00512", label: "MS Medicare Part B" },
  { value: "MTBLS", label: "MT BCBS" },
  { value: "MCDMT", label: "MT Medicaid" },
  { value: "03201", label: "MT Medicare Part A" },
  { value: "03001", label: "MT Medicare Part B" },
  { value: "14165", label: "MVP Health Plan of NY" },
  { value: "53011", label: "NALC" },
  { value: "SB810", label: "NC BCBS" },
  { value: "NCMCD", label: "NC Medicaid" },
  { value: "11502", label: "NC Medicare Part B" },
  { value: "00320", label: "ND Blue Cross" },
  { value: "00820", label: "ND Blue Shield" },
  { value: "NDMCD", label: "ND Medicaid" },
  { value: "03302", label: "ND Medicare Part B" },
  { value: "00760", label: "NE BCBS" },
  { value: "SKNE0", label: "NE Medicaid" },
  { value: "05401", label: "NE Medicare Part A" },
  { value: "05402", label: "NE Medicare Part B" },
  { value: "00270", label: "NH BCBS Institutional" },
  { value: "00770", label: "NH BCBS Professional" },
  { value: "NHMCD", label: "NH Medicaid" },
  { value: "14312", label: "NH Medicare Part B" },
  { value: "81264", label: "Nippon Life Insurance Company of America" },
  { value: "22099", label: "NJ BCBS" },
  { value: "NJMCD", label: "NJ Medicaid" },
  { value: "12401", label: "NJ Medicare Part A" },
  { value: "12402", label: "NJ Medicare Part B" },
  { value: "00790", label: "NM BCBS" },
  { value: "MC721", label: "NM BCBS - Community Centennial" },
  { value: "NMMAD", label: "NM Medicaid" },
  { value: "04211", label: "NM Medicare Part A" },
  { value: "04202", label: "NM Medicare Part B" },
  { value: "12M07", label: "Noridian Medicare ID" },
  { value: "12M82", label: "Noridian Medicare ND" },
  { value: "12M83", label: "Noridian Medicare SD" },
  { value: "12M45", label: "Noridian Medicare WA" },
  { value: "12M30", label: "Noridian Medicare WY" },
  { value: "71080", label: "Novasys Health Network" },
  { value: "00265", label: "NV BCBS" },
  { value: "SKNV0", label: "NV Medicaid" },
  { value: "01302", label: "NV Medicare Part B" },
  { value: "00803", label: "NY BCBS - Empire" },
  { value: "00303", label: "NY BCBS - Empire Institutional" },
  { value: "00800", label: "NY BCBS - Northeastern" },
  { value: "00301", label: "NY BCBS - Western" },
  { value: "NYMCD", label: "NY Medicaid" },
  { value: "13201", label: "NY Medicare Part A" },
  { value: "13202", label: "NY Medicare Part B Downstate" },
  { value: "13292", label: "NY Medicare Part B Queens" },
  { value: "13282", label: "NY Medicare Part B Upstate " },
  { value: "00332", label: "OH BCBS Institutional" },
  { value: "00834", label: "OH BCBS Professional" },
  { value: "SKOH0", label: "OH Medicaid" },
  { value: "15202", label: "OH Medicare Part B" },
  { value: "00840", label: "OK BCBS" },
  { value: "OKMCD", label: "OK Medicaid" },
  { value: "04311", label: "OK Medicare Part A" },
  { value: "04302", label: "OK Medicare Part B" },
  { value: "54154", label: "Optima/Sentara Health Plan" },
  { value: "00851", label: "OR BCBS (Regence)" },
  { value: "SKOR0", label: "OR Medicaid" },
  { value: "02302", label: "OR Medicare Part B" },
  { value: "RP039", label: "Oscar - Health First Health Plans" },
  { value: "OSCAR", label: "Oscar Insurance" },
  { value: "06111", label: "Oxford Health Plans" },
  { value: "SB865", label: "PA BCBS" },
  { value: "PAMCD", label: "PA Medicaid" },
  { value: "12502", label: "PA Medicare Part B" },
  { value: "95959", label: "PacifiCare" },
  { value: "93029", label: "PacificSource Health Plans" },
  { value: "61325", label: "Passport Health Plan" },
  { value: "68049", label: "Peach State Health Plan" },
  {
    value: "95397",
    label: "Personal Insurance Administrators  Inc Agoura Hills CA",
  },
  { value: "88058", label: "PHX - Med-Pay" },
  { value: "47027", label: "Physicians Mutual Insurance Comp" },
  { value: "65088", label: "Preferred Care Partners Florida" },
  { value: "41147", label: "PreferredOne MN" },
  { value: "45056", label: "Prestige Health Choice" },
  { value: "38217", label: "Priority Health" },
  { value: "SX133", label: "Providence Health Plan" },
  { value: "SX187", label: "Providence Preferred PPO" },
  { value: "35174", label: "Qual Choice of Arkansas" },
  { value: "39180", label: "QUARTZ" },
  { value: "00882", label: "Railroad Medicare" },
  { value: "RGA01", label: "Regence Group Administrators" },
  { value: "00870", label: "RI BCBS" },
  { value: "14412", label: "RI Medicare Part B" },
  { value: "SX141", label: "Rocky Mountain Health Plan" },
  { value: "91184", label: "Sanford Health Plan" },
  { value: "00401", label: "SC BCBS" },
  { value: "SCMCD", label: "SC Medicaid" },
  { value: "11001", label: "SC Medicare Part A" },
  { value: "11202", label: "SC Medicare Part B" },
  { value: "SDBLS", label: "SD BCBS" },
  { value: "SDMCD", label: "SD Medicaid" },
  { value: "03402", label: "SD Medicare Part B" },
  { value: "LIFE1", label: "Secure Horizons Lifeprint Arizona" },
  { value: "SX107", label: "Select Health" },
  { value: "23285", label: "Select Health of South Carolina" },
  { value: "23250", label: "Significa Benefits Services, Inc." },
  { value: "SMPLY", label: "Simply Healthcare" },
  { value: "31059", label: "State Farm Casualty  Property Claims" },
  { value: "74227", label: "Student Resources (UnitedHealthcare)" },
  { value: "PASSE", label: "Summit Community Care" },
  { value: "68057", label: "Sunshine State Health Plan" },
  { value: "C5781", label: "Surecare Benefits" },
  { value: "TEST1", label: "Test Payer" },
  { value: "23223", label: "The Loomis Company TPA Wyomissi" },
  { value: "00390", label: "TN BCBS" },
  { value: "10311", label: "TN Medicare Part A" },
  { value: "10312", label: "TN Medicare Part B" },
  { value: "TREST", label: "Tricare East Region" },
  { value: "99726", label: "Tricare West Region" },
  { value: "61425", label: "Trustmark Small Business Benefits" },
  { value: "04298", label: "Tufts Health Plan" },
  { value: "84980", label: "TX BCBS" },
  { value: "86916", label: "TX Medicaid" },
  { value: "04411", label: "TX Medicare Part A" },
  { value: "04402", label: "TX Medicare Part B" },
  { value: "55413", label: "UCare Plans" },
  { value: "39026", label: "UMR - Wausau" },
  { value: "80314", label: "UNICARE" },
  { value: "87042", label: "Union Pacific Railroad" },
  { value: "87726", label: "United Health Care" },
  { value: "95378", label: "United Healthcare of River Valley" },
  { value: "71412", label: "United of Omaha" },
  { value: "86050", label: "UnitedHealthcare Community Plan Missouri" },
  {
    value: "03432",
    label:
      "UnitedHealthcare Community Plan/AZ, Long Term Care, Children's Rehabilitative Services (CRS)",
  },
  { value: "23281", label: "UPMC Health Plan" },
  { value: "00910", label: "UT BCBS" },
  { value: "UTMCD", label: "UT Medicaid" },
  { value: "03502", label: "UT Medicare Part B" },
  { value: "00423", label: "VA BCBS" },
  { value: "VAMCD", label: "VA Medicaid" },
  { value: "12004", label: "VA Medicare Part A" },
  { value: "11302", label: "VA Medicare Part B" },
  { value: "63114", label: "Viva Health Plan" },
  { value: "77073", label: "VNS CHOICE Medicare" },
  { value: "SB915", label: "VT BCBS" },
  { value: "VTMCD", label: "VT Medicaid" },
  { value: "14512", label: "VT Medicare Part B" },
  { value: "00932", label: "WA Blue Shield - Regence" },
  { value: "SKWA0", label: "WA Medicaid" },
  { value: "02402", label: "WA Medicare Part B" },
  { value: "00430", label: "WA/AK Blue Cross - Premera" },
  { value: "75261", label: "WebTPA" },
  { value: "14163", label: "Wellcare" },
  { value: "00450", label: "WI BCBS Institutional" },
  { value: "00950", label: "WI BCBS Professional" },
  { value: "WIMCD", label: "WI Medicaid" },
  { value: "06001", label: "WI Medicare Part A" },
  { value: "06302", label: "WI Medicare Part B" },
  { value: "54828", label: "WV BCBS" },
  { value: "SKWV0", label: "WV Medicaid" },
  { value: "11402", label: "WV Medicare Part B" },
  { value: "00460", label: "WY Blue Cross" },
  { value: "00960", label: "WY Blue Shield" },
  { value: "WYMCD", label: "WY Medicaid" },
  { value: "03602", label: "WY Medicare Part B" },
];
