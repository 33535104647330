import logo from './logo.svg';
import NewForm from "./NewForm";
function App() {
  return (
   <>
  <NewForm></NewForm>
   </>
  );
}

export default App;
