import React, { useEffect, useState } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import { Input, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import Radio from "@mui/material/Radio";
import axios from "axios";
import payersList from "./data/payersList";
import eligibilityInfo from "./data/eligibilityInfo";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Button } from "@material-ui/core";
import { createBrowserHistory } from "history";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Controller, useForm } from "react-hook-form";
import BenefitItem from "./BenefitItem";

const useStyles = makeStyles((theme) => ({
  Outline: {
    borderWidth: "1px",
    borderColor: "black !important",
    fontSize: "12px",
  },
  root: {
    flexGrow: 1,
  },
  input: {
    "& > *": {
      marginTop: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));
const history = createBrowserHistory({ forceRefresh: true });
const relationShipOptions = [
  { value: "01", label: "Spouse" },
  { value: "04", label: "Grandfather or Grandmother" },
  { value: "05", label: "Grandson or Granddaughter" },
  { value: "07", label: "Nephew or Niece" },
  { value: "09", label: "Adopted Child" },
  { value: "10", label: "Foster Child" },
  { value: "15", label: "Ward" },
  { value: "17", label: "Stepson or Stepdaughter" },
  { value: "18", label: "Self" },
  { value: "19", label: "Child" },
  { value: "20", label: "Employee" },
  { value: "21", label: "Unknown" },
  { value: "22", label: "Handicapped Dependent" },
  { value: "23", label: "Sponsored Dependent" },
  { value: "24", label: "Dependent of a Minor Dependent" },
  { value: "29", label: "Significant Other" },
  { value: "32", label: "Mother" },
  { value: "33", label: "Father" },
  { value: "34", label: "Other Adult" },
  { value: "36", label: "Emancipated Minor" },
  { value: "39", label: "Organ Donor" },
  { value: "40", label: "Cadaver Donor" },
  { value: "41", label: "Injured Plaintiff" },
  { value: "43", label: "Child Where Insured Has No Financial Responsibility" },
  { value: "53", label: "Life Partner" },
  { value: "G8", label: "Other Relationship" },
];
const fields = [
  "ins_name_f",
  "ins_dob",
  "ins_number",
  "prov_npi",
  "pat_rel",
  "payerid",
  "fdos",
  "service_code",
  "proc_code",
];
const getDate = () => {
  var dt = new Date();
  console.log("Date: ", dt.getDay());
  const currentHours =
    dt.getFullYear() +
    "-" +
    ("0" + (dt.getDay() - 1)).slice(-2) +
    "-" +
    ("0" + dt.getDate()).slice(-2);
  return currentHours;
};
const convertDate = (date) => {
  var dt = date.split("-");
  var currentHours =
    dt[0].slice(4, 6) + "/" + dt[0].slice(6, 8) + "/" + dt[0].slice(0, 4);
  if (dt.length > 1)
    currentHours =
      currentHours +
      "-" +
      dt[1].slice(4, 6) +
      "/" +
      dt[1].slice(6, 8) +
      "/" +
      dt[1].slice(0, 4);
  return currentHours;
};
const defValues = {
  ins_name_f: "",
  ins_dob: "",
  ins_number: "",
  prov_npi: "1588288591",
  pat_rel: "18",
  payerid: "01102",
  fdos: getDate(),
  service_code: "30",
  proc_code: "",
};
export default function NewForm() {
  const [age, setAge] = useState("");
  const classes = useStyles();
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  // const location = useLocation()
  // const alert = useAlert()
  const [submitting, setSubmitting] = useState();
  const [errorReceived, seterrorReceived] = useState("");
  const [insuranceName, setinsuranceName] = useState("");
  const [effectiveDate, seteffectiveDate] = useState("");

  const [responseReceived, setResponseReceived] = useState(false);

  const [eligibility_begin_date, seteligibility_begin_date] = useState("");
  const [ins_addr_1, setins_addr_1] = useState("");
  const [ins_city, setins_city] = useState("");
  const [ins_state, setins_state] = useState("");
  const [ins_zip, setins_zip] = useState("");
  const [ins_dob, setins_dob] = useState("");
  const [ins_name_f, setins_name_f] = useState("");
  const [ins_name_l, setins_name_l] = useState("");
  const [ins_name_m, setins_name_m] = useState("");
  const [ins_number, setins_number] = useState("");
  const [ins_sex, setins_sex] = useState("");
  const [deductibleAmount, setdeductibleAmount] = useState("");
  const [deductibleRemaining, setdeductibleRemaining] = useState("");
  const [coinsurancePercent, setcoinsurancePercent] = useState("");
  const [eligibility_date, seteligibility_date] = useState("");

  const [plan_begin_date, setplan_begin_date] = useState("");
  const [plan_number, setplan_number] = useState("");
  const [service_date, setservice_date] = useState("");
  const [group_name, setgroup_name] = useState("");
  const [group_number, setgroup_number] = useState("");
  const [benefitsList, setbenefitsList] = useState([]);
  var [deductiblesList, setdeductiblesList] = useState([]);
  var [skipNext, setskipNext] = useState(false);
  var [skipNextItems, setskipNextItems] = useState(0);

  const headers = {
    "Access-Control-Allow-Origin": "*",
    accept: "text/plain",
    "content-type": "application/json",
    crossorigin: true,
  };
  const validationSchema = Yup.object({
    ins_name_l: Yup.string().required("Last Name is required"),
    ins_name_f: Yup.string().required("First Name is required"),
    ins_dob: Yup.string().required("DOB is required"),
    ins_number: Yup.string().required("Policy Number is required"),
    prov_npi: Yup.string().required("Provider NPI is required"),
    pat_rel: Yup.string().required("RelationShip is required"),
    payerid: Yup.string().required("Payer ID is required"),
    fdos: Yup.string().required("DOS is required"),
    service_code: Yup.string().required("Benefit Type is required"),
  });
  const [payerIdValue, setpayerIdValue] = useState("");
  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues,
  });
  useEffect(() => {
    setValue("pat_rel", "18");
  }, []);
  const onSubmit = (data) => {
    setSubmitting(true);
    seterrorReceived("");
    const postData = data;
    // (postData.accountKey = "string"),
    // postData.ins_name_l = "CHASE";
    // postData.ins_name_f = "BARRY";
    // postData.ins_name_m = "";
    // postData.payerid = "01192";
    // (postData.pat_rel = "18"), (postData.fdos = "2022-04-24");
    // postData.ins_number = "7JF5M18RF87";
    // postData.ins_dob = "1955-08-17";
    // postData.service_code = "30";

    const axiosConfig = {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    console.log("postData: ", postData);
    //  var newpostData={ postData};
    // postData.userType = data.userType ? data.userType.value : ''
    // postData.gender = data.gender ? data.gender.value : ''
    /* axios({
      method: "POST",
      url: 'https://localhost:44309/api/Eligibility/sendRequest',
      timeout: 1000 * 15, // Wait for 5 seconds
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'Access-Control-Allow-Origin': '*',
        'accept': 'text/plain'
      },
      postData
    }) */
    axios
      .post(
        `https://eligibilityapi.devforhealth.com/api/Eligibility/sendRequest`,
        postData,
        axiosConfig
      )
      .then((response) => {
        setSubmitting(false);
        console.log("login--res", response.data);
        if (response.data.error) {
          seterrorReceived(response.data.error.error_mesg);
        } else if (response.data.elig) {
          setResponseReceived(true);
          var elig = response.data.elig;
          seteligibility_begin_date(elig.eligibility_begin_date);
          setins_addr_1(elig.ins_addr_1);
          setins_city(elig.ins_city);
          setins_state(elig.ins_state);
          setins_zip(elig.ins_zip);
          setins_dob(elig.ins_dob);
          setins_name_f(elig.ins_name_f);
          setins_name_l(elig.ins_name_l);
          setins_name_m(elig.ins_name_m);
          setins_number(elig.ins_number);
          setins_sex(elig.ins_sex);
          seteligibility_date(elig.eligibility_date);
          setplan_begin_date(elig.plan_begin_date);
          setplan_number(elig.plan_number);
          setservice_date(elig.service_date);
          setgroup_name(elig.group_name);
          setgroup_number(elig.group_number);
          var allBenefits = elig.benefit;
          var dedList = [];
          var benefits = [];
          allBenefits.forEach((data) => {
            console.log("DDD: ", data.benefit_coverage_code);
            console.log("DDD11: ", data.benefit_coverage_code == "C");
            if (data.benefit_coverage_code == "1") {
              setinsuranceName(data.insurance_type_description);
              seteffectiveDate(data.plan_date);
            }
            if (
              data.benefit_coverage_code == "C" ||
              data.benefit_coverage_code == "A" ||
              data.benefit_coverage_code == "U"
            ) {
              dedList.push(data);
              if (
                data.benefit_coverage_code == "C" &&
                data.benefit_amount > 0 &&
                data.insurance_type_description == "Medicare Part B" &&
                data.benefit_period_code == "23"
              ) {
                setdeductibleAmount(data.benefit_amount);
              }
              if (
                data.benefit_coverage_code == "C" &&
                data.benefit_amount > 0 &&
                data.insurance_type_description == "Medicare Part B" &&
                data.benefit_period_description == "Remaining"
              ) {
                setdeductibleRemaining(data.benefit_amount);
              }
              if (
                data.benefit_coverage_code == "A" &&
                data.benefit_percent > 0
              ) {
                setcoinsurancePercent(data.benefit_percent);
              }
            } else benefits.push(data);
          });
          setdeductiblesList(dedList);
          setbenefitsList(benefits);
        }
      })
      .catch((error) => {
        setSubmitting(false);
      });

    /*  axios
      .post(
        `https://eligibilityapi.devforhealth.com/api/Eligibility`,
       { postData},{
          headers: {  "Content-Type": "application/json", }
        } 
      )
      .then((response) => {
        setSubmitting(false)
        alert(response.data)
        // alert.success('Record saved successfully.', {
        //   type: 'success',
        //   onClose: () => {
        //     history.push('/users')
        //   },
        // })
      })
      .catch((error) => {
        setSubmitting(false)
        alert.success(`Error ${error.message}`, {
          type: 'error',
        })
      }) */
  };
  return (
    <div>
      {responseReceived ? (
        <Grid
          container
          item
          xs={12}
          lg={6}
          style={{
            padding: "15px",
          }}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                borderTop: 1,
                borderColor: "divider",
                marginBottom: "15px",
              }}
            >
              <Grid
                item
                xs={12}
                style={{ height: "40px" }}
                bgcolor={"rgb(161,230,246)"}
              >
                <ArrowBackIosIcon
                  color="black"
                  style={{ marginLeft: "5px", float: "left", marginTop: "5px" }}
                  onClick={(event) => {
                    setResponseReceived(false);
                    // setnewNote(false)
                    // fields.forEach((field) => setValue(field, ''))
                  }}
                  variant="gradient"
                />
                <Typography
                  component="label"
                  style={{
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                  // paddingTop="50px"
                >
                  {/* <PermIdentity style={{ paddingTop: "4px" }} color="dark" /> */}
                  Eligibility Details
                </Typography>
              </Grid>
            </Box>
          </Grid>

          <Grid
            container
            lg={12}
            spacing={1}
            alignContent="center"
            justifyContent="center"
            style={{ paddingLeft: "25px" }}
          >
            {ins_name_l && ins_name_l.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Name:
                  </Typography>
                </Grid>
                <Grid xs={9} lg={3}>
                  <Typography
                    style={{ fontSize: "14px" }}
                  >{`${ins_name_l}, ${ins_name_f} ${ins_name_m} `}</Typography>
                </Grid>{" "}
              </Grid>
            ) : null}
            {ins_number && ins_number.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Policy # :
                  </Typography>
                </Grid>
                <Grid xs={9} lg={3}>
                  <Typography
                    style={{ fontWeight: 300 }}
                  >{`${ins_number} `}</Typography>
                </Grid>
              </Grid>
            ) : null}
            {ins_dob && ins_dob.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    DOB :
                  </Typography>
                </Grid>
                <Grid xs={9} lg={3}>
                  <Typography style={{ fontWeight: 300 }}>{`${convertDate(
                    ins_dob
                  )} `}</Typography>
                </Grid>
              </Grid>
            ) : null}
            {ins_sex && ins_sex.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Gender :
                  </Typography>
                </Grid>
                <Grid xs={9} lg={3}>
                  <Typography style={{ fontWeight: 300 }}>{`${
                    ins_sex == "M" ? "Male" : "Female"
                  } `}</Typography>
                </Grid>
              </Grid>
            ) : null}
            {ins_addr_1 && ins_addr_1.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Address :
                  </Typography>
                </Grid>
                <Grid xs={9} lg={6}>
                  <Typography
                    style={{ fontWeight: 300 }}
                  >{`${ins_addr_1} `}</Typography>
                  <Typography
                    item
                    style={{ fontWeight: 300 }}
                  >{`  ${ins_city} ${ins_state} ${ins_zip}`}</Typography>
                </Grid>
              </Grid>
            ) : null}
            {eligibility_date && eligibility_date.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    Eligibility :
                  </Typography>
                </Grid>
                <Grid xs={9} lg={3}>
                  <Typography style={{ fontWeight: 300 }}>{`${convertDate(
                    eligibility_date
                  )} `}</Typography>
                </Grid>
              </Grid>
            ) : null}
            <Grid
              item
              xs={12}
              lg={12}
              bgcolor={"rgb(169, 182, 186)"}
              style={{
                paddingBottom: "5px",
              }}
            >
              <Typography
                component="label"
                style={{ fontWeight: "bold", fontSize: "15px" }}
              >
                Insurance Coverage Summary
              </Typography>
            </Grid>
            {insuranceName && insuranceName.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Name :
                  </Typography>
                </Grid>
                <Grid xs={9} lg={3}>
                  <Typography
                    style={{ fontWeight: 300 }}
                  >{`${insuranceName} `}</Typography>
                </Grid>
              </Grid>
            ) : null}
            {effectiveDate && effectiveDate.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Effective Date :
                  </Typography>
                </Grid>
                <Grid xs={9} lg={3}>
                  <Typography style={{ fontWeight: 300 }}>{`${convertDate(
                    effectiveDate
                  )} `}</Typography>
                </Grid>
              </Grid>
            ) : null}
            {ins_number && ins_number.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid lg={3}>
                  <Typography
                    component="label"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Co-Insurance :
                  </Typography>
                </Grid>
                <Grid lg={3}>
                  <Typography style={{ fontWeight: 300 }}>
                    {coinsurancePercent}%{" "}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {ins_number && ins_number.length > 0 ? (
              <Grid
                container
                xs={12}
                lg={12}
                style={
                  {
                    // width: "700px",
                    // paddingLeft: "100px",
                    // paddingRight: "100px",
                  }
                }
              >
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Deductible :
                  </Typography>
                </Grid>
                <Grid xs={9} lg={3}>
                  <Typography style={{ fontWeight: 300 }}>
                    ${deductibleAmount}{" "}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {deductibleRemaining && deductibleRemaining.length > 0 ? (
              <Grid container xs={12} lg={12}>
                <Grid xs={3} lg={3}>
                  <Typography
                    component="label"
                    style={{ fontSize: "14px", fontWeight: "bold" }}
                  >
                    Deductible Remaining:
                  </Typography>
                </Grid>
                <Grid xs={9} lg={3}>
                  <Typography style={{ fontWeight: 300 }}>
                    ${deductibleRemaining}{" "}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {group_name && group_name.length > 0 ? (
              <Grid lg={12}>
                <Grid item xs={6} lg={2}>
                  <Typography component="label" style={{ fontWeight: "bold" }}>
                    Plan Name :
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  lg={3}
                  style={{
                    // width: "700px",
                    padding: "5px",
                    // paddingLeft: "100px",
                    // paddingRight: "200px",
                  }}
                >
                  <Typography style={{ fontWeight: 300 }}>
                    {group_name}
                  </Typography>
                </Grid>{" "}
              </Grid>
            ) : null}
            {group_number && group_number.length > 0 ? (
              <Grid lg={12}>
                {" "}
                <Grid
                  item
                  xs={6}
                  lg={12}
                  style={{
                    width: "100%",
                    padding: "5px",
                    paddingLeft: "50px",
                    // paddingRight: "100px",
                  }}
                >
                  <Typography component="label" style={{ fontWeight: "bold" }}>
                    Group#:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  lg={3}
                  style={{
                    // width: "700px",
                    padding: "5px",
                    // paddingLeft: "100px",
                    // paddingRight: "100px",
                  }}
                >
                  <Typography style={{ fontWeight: 300 }}>
                    {group_number}
                  </Typography>
                </Grid>{" "}
              </Grid>
            ) : null}
            {plan_begin_date && plan_begin_date.length > 0 ? (
              <Grid lg={12}>
                <Grid
                  item
                  xs={6}
                  lg={12}
                  style={{
                    width: "20%",
                    padding: "5px",
                    paddingLeft: "50px",
                    // alignContent:"right"
                    // paddingRight: "100px",
                  }}
                >
                  <Typography component="label" style={{ fontWeight: "bold" }}>
                    Plan Begin date:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  lg={3}
                  style={{
                    // width: "700px",
                    padding: "5px",
                    // paddingLeft: "100px",
                    // paddingRight: "200px",
                  }}
                >
                  <Typography style={{ fontWeight: 300 }}>
                    {plan_begin_date}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}
            {/* {group_name && group_name.length > 0 ? (
              <Grid lg={12}>
            <Grid
              item
              xs={6}
              lg={12} />
            <Grid
              item
              xs={6}
              lg={2}
              style={{
                width: "20%",
                padding: "5px",
                paddingLeft: "50px", 
              }}
            >
              <Typography component="label" style={{ fontWeight: "bold" }}>
                Group Name:
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              lg={3}
              style={{
                // width: "700px",
                padding: "5px", 
              }}
            >
              <Typography style={{ fontWeight: 300 }}>{group_name}</Typography>
            </Grid>
            </Grid>
            ) : null} */}

            {eligibility_begin_date && eligibility_begin_date.length > 0 ? (
              <Grid lg={12}>
                <Grid
                  item
                  xs={6}
                  lg={12}
                  style={{
                    width: "20%",
                    padding: "5px",
                    paddingLeft: "50px",
                    // alignContent:"right"
                    // paddingRight: "100px",
                  }}
                >
                  <Typography component="label" style={{ fontWeight: "bold" }}>
                    Eligibilty Begin date:
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  lg={3}
                  style={{
                    // width: "700px",
                    padding: "5px",
                    // paddingLeft: "100px",
                    // paddingRight: "200px",
                  }}
                >
                  <Typography style={{ fontWeight: 300 }}>
                    {eligibility_begin_date}
                  </Typography>
                </Grid>
              </Grid>
            ) : null}

            {deductiblesList && deductiblesList.length > 0 ? (
              <Grid
                item
                xs={6}
                lg={12}
                style={{
                  padding: "5px",
                  // alignContent:"right"
                  // paddingRight: "100px",
                }}
              >
                <Typography
                  component="label"
                  style={{ fontWeight: "bold", fontSize: "25px" }}
                >
                  Deductibles
                </Typography>
              </Grid>
            ) : null}

            {deductiblesList.map((item, i) => {
              if (skipNext) {
              }
              console.log("deductibles:", item);
              return (
                <Grid
                  key={i}
                  lg={12}
                  style={{
                    padding: "15px",
                    marginTop: "5px",
                  }}
                  bgcolor={"rgb(161,230,246)"}
                >
                  {/* <BenefitItem item={item}> </BenefitItem> */}
                  {item.benefit_description ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        // style={{ fontWeight: "bold" }}
                        component="label"
                      >
                        {item.benefit_description}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.benefit_level_description ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Benefit Level:
                      </Typography>

                      <Typography component="label">
                        {item.benefit_level_description}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.plan_date ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Plan Date:
                      </Typography>

                      <Typography component="label">
                        {convertDate(item.plan_date)}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.coordination_of_benefits ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Coordination of Benefits:
                      </Typography>

                      <Typography component="label">
                        {convertDate(item.coordination_of_benefits)}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.insurance_type_description ? (
                    <Grid container xs={12} lg={12}>
                      <Typography
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                        component="label"
                      >
                        {item.insurance_type_description}{" "}
                        {item.benefit_coverage_description}{" "}
                        {item.benefit_amount ? "$" : ""}
                        {item.benefit_amount} {"  "}
                        {item.benefit_percent} {"  "}
                        {item.benefit_percent ? "% " : " "}
                        {item.benefit_period_description}
                      </Typography>
                    </Grid>
                  ) : /*    { ({i}+1)}<deductiblesList.length?
<Grid container xs={12} lg={12}>
          <Typography
            style={{ fontSize: "14px", fontWeight: "bold" }}
            component="label"
          >
            {item.insurance_type_description}{" "}
            {item.benefit_coverage_description}{" "}
            {item.benefit_amount ? "$" : ""}
            {item.benefit_amount}
          </Typography>
        </Grid>
        // deductiblesList[i+1]
        :null */

                  null}

                  {item.benefit_begin ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Begin:
                      </Typography>

                      <Typography component="label">
                        {item.benefit_begin}
                      </Typography>
                    </Grid>
                  ) : null}

                  {item.plan_number ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Plan Number:
                      </Typography>

                      <Typography component="label">
                        {item.plan_number}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.mco_name ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Plan Sponsor:
                      </Typography>

                      <Typography component="label">{item.mco_name}</Typography>
                    </Grid>
                  ) : null}
                  {item.mco_number ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Plan Network ID:
                      </Typography>

                      <Typography component="label">
                        {item.mco_number}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.entity_name ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Primary Payer:
                      </Typography>

                      <Typography component="label">
                        {item.entity_name}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.entity_addr_1 ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Address:
                      </Typography>

                      <Typography component="label">
                        {item.entity_addr_1} {item.entity_city}{" "}
                        {item.entity_state}
                        {"   "} {item.entity_zip}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.entity_phone ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Phone:
                      </Typography>

                      <Typography component="label">
                        {item.entity_phone}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.entity_website ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontSize: "14px", fontWeight: "bold" }}
                      >
                        Website:
                      </Typography>

                      <Typography component="label">
                        {item.entity_website}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.benefit_notes ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Notes:
                      </Typography>

                      <Typography component="label">
                        {item.benefit_notes}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              );
            })}

            <Grid
              item
              xs={6}
              lg={12}
              style={{
                width: "20%",
                padding: "5px",
                paddingLeft: "50px",
                // alignContent:"right"
                // paddingRight: "100px",
              }}
            >
              <Typography
                component="label"
                style={{ fontWeight: "bold", fontSize: "30px" }}
              >
                Benefits
              </Typography>
            </Grid>
            {benefitsList.map((item, i) => {
              console.log("item:", item);
              return (
                <Grid
                  key={i}
                  lg={12}
                  style={{
                    padding: "15px",
                    marginTop: "5px",
                    paddingLeft: "50px",
                  }}
                  bgcolor={"rgb(161,230,246)"}
                >
                  {item.benefit_description ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Benefit Description:
                      </Typography>

                      <Typography component="label">
                        {item.benefit_description}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.benefit_level_description ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Benefit Level:
                      </Typography>

                      <Typography component="label">
                        {item.benefit_level_description}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.benefit_amount ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Benefit Amount :
                      </Typography>

                      <Typography
                        component="label"
                        style={{ paddingleft: "5px" }}
                      >
                        ${item.benefit_amount}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.insurance_type_description ? (
                    <Grid item xs={12} lg={12}>
                      <Typography component="label">
                        {item.insurance_type_description}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.plan_number ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Plan Number:
                      </Typography>

                      <Typography component="label">
                        {item.plan_number}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.benefit_begin ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Begin:
                      </Typography>

                      <Typography component="label">
                        {item.benefit_begin}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.benefit_coverage_description ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Coverage:
                      </Typography>

                      <Typography component="label">
                        {item.benefit_coverage_description}
                      </Typography>
                    </Grid>
                  ) : null}
                  {item.benefit_notes ? (
                    <Grid item xs={6} lg={12}>
                      <Typography
                        component="label"
                        style={{ fontWeight: "bold" }}
                      >
                        Notes:
                      </Typography>

                      <Typography component="label">
                        {item.benefit_notes}
                      </Typography>
                    </Grid>
                  ) : null}
                </Grid>
              );
            })}

            <Grid item xs={6} lg={7} />
          </Grid>
        </Grid>
      ) : (
        <Grid Container spacing={3}>
          <Grid item></Grid>
          <Grid item Container>
            <Grid item xs={0} sm={3}></Grid>
            <Grid item xs={12} sm={6}>
              <Container maxWidth="sm">
                <Box
                  sx={{
                    bgcolor: "#cfe8fc",
                    width: "100vh",
                    border: 1,
                    fontWeight: "bold",
                    marginTop: 1,
                    p: 1,
                  }}
                >
                  Eligibility Request
                  <Box
                    sx={{
                      bgcolor: "#cfe8fc",
                      border: 1,
                      fontWeight: "bold",
                      marginTop: 1,
                      marginLeft: 2,
                      p: 1,
                    }}
                  >
                    <Grid container spacing={1} item>
                      {/* <Grid item xs={6} lg={6}>
                        <Typography
                          variant="headline"
                          component="h5"
                          color="black"
                          marginTop="10px"
                          marginBottom="10px"
                        >
                          Provider
                        </Typography>
                        <Select
                          className={classes.input}
                          id="outlined-basic"
                          fullWidth="100%"
                          variant="outlined"
                          {...register("prov_npi")}
                          error={errors.prov_npi}
                          size="small"
                          InputProps={{
                            classes: {
                              notchedOutline: classes.Outline,
                            },
                            style: { fontSize: "1.3rem" },
                          }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value={1588288591}>KIM & LEE A DENTAL CORPORATION</MenuItem>
                        </Select>
                      </Grid> */}
                      <Grid item xs={6} lg={12}>
                        <Typography
                          variant="headline"
                          component="h5"
                          color="black"
                          marginTop="10px"
                          marginBottom="10px"
                        >
                          Payer
                        </Typography>
                      </Grid>
                      <Grid item xs={6} lg={12}>
                        <Stack spacing={2}>
                          <Controller
                            name="payerid"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                freeSolo
                                id="free-solo-2-demo"
                                disableClearable
                                defaultValue={payersList.find(
                                  (ob) => ob.value === "01102"
                                )}
                                onChange={(event, value) => {
                                  console.log("PayerId: ", value);
                                  setValue("payerid", value.value);
                                  setinsuranceName(value.label);
                                }}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.label}</li>
                                )}
                                options={payersList}
                                renderInput={(params) => (
                                  <TextField
                                    error={errors.payerid}
                                    fullWidth
                                    {...params}
                                    value={payerIdValue ?? ""}
                                    placeholder="Search Payer"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                      size: "small",
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </Stack>
                        <p style={{ fontSize: "10px", color: "red" }}>
                          {errors.payerid?.message}
                        </p>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Typography
                          variant="headline"
                          component="h5"
                          color="black"
                          marginTop="1px"
                        >
                          Benefit Type
                        </Typography>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Typography
                          variant="headline"
                          component="h5"
                          color="black"
                          marginTop="1px"
                        >
                          Procedure Code
                        </Typography>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Stack spacing={2}>
                          <Controller
                            name="servicecode"
                            control={control}
                            render={({ field }) => (
                              <Autocomplete
                                freeSolo
                                id="serviceCode"
                                disableClearable
                                defaultValue={eligibilityInfo.find(
                                  (ob) => ob.value === "30"
                                )}
                                onChange={(event, value) => {
                                  setValue("service_code", value.value);
                                }}
                                renderOption={(props, option) => (
                                  <li {...props}>{option.label}</li>
                                )}
                                options={eligibilityInfo}
                                renderInput={(params) => (
                                  <TextField
                                    fullWidth
                                    {...params}
                                    placeholder="Search Benefit Type"
                                    InputProps={{
                                      ...params.InputProps,
                                      type: "search",
                                      size: "small",
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </Stack>
                        <p style={{ fontSize: "10px", color: "red" }}>
                          {errors.service_code?.message}
                        </p>
                      </Grid>
                      <Grid item xs={6} lg={6}>
                        <Input
                          placeholder="Please enter Code"
                          fullWidth
                          margin="dense"
                          {...register("proc_code")}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      bgcolor: "#cfe8fc",
                      border: 1,
                      fontWeight: "bold",
                      marginTop: 1,
                      marginLeft: 2,
                      p: 1,
                    }}
                  >
                    Subscriber information
                    <Grid
                      container
                      spacing={1}
                      style={{
                        paddingTop: "20px",
                      }}
                    >
                      <Grid item xs={6} style={{ width: "300px" }}>
                        <Typography
                          variant="headline"
                          component="h5"
                          fontWeight="bold"
                        >
                          First Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ width: "300px" }}>
                        <Typography
                          component="h5"
                          variant="headline"
                          fontWeight="bold"
                        >
                          Last Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          placeholder="Please enter First Name"
                          fullWidth
                          margin="dense"
                          {...register("ins_name_f")}
                          error={errors.ins_name_f}
                        />
                        <p style={{ fontSize: "10px", color: "red" }}>
                          {errors.ins_name_f?.message}
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          placeholder="Last Name"
                          fullWidth
                          margin="dense"
                          {...register("ins_name_l")}
                          error={errors.ins_name_l}
                        />
                        <p style={{ fontSize: "10px", color: "red" }}>
                          {errors.ins_name_l?.message}
                        </p>
                      </Grid>
                      <Grid item xs={6} style={{ width: "300px" }}>
                        <Typography
                          component="h5"
                          variant="headline"
                          fontWeight="bold"
                        >
                          RelationShip
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ width: "300px" }}>
                        <Typography
                          component="h5"
                          variant="headline"
                          fontWeight="bold"
                        >
                          Policy #
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          name={name}
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              freeSolo
                              id="free-solo-2-demo"
                              disableClearable
                              onChange={(event, value) =>
                                setValue("pat_rel", value.value)
                              }
                              defaultValue={relationShipOptions.find(
                                (ob) => ob.value === "18"
                              )}
                              // isOptionEqualToValue={(option, value) => option.value === value.value}
                              /*  isOptionEqualToValue={(option, value) => {
                            
                            return getValues("pat_rel")==value
                          }} */
                              renderOption={(props, option) => (
                                <li {...props}>{option.label}</li>
                              )}
                              options={relationShipOptions || []}
                              value={value}
                              renderInput={(params) => (
                                <TextField
                                  style={{ marginTop: "3px" }}
                                  {...register("pat_rel")}
                                  error={errors.pat_rel}
                                  {...params}
                                  value={payerIdValue ?? ""}
                                  placeholder="Search RelationShip"
                                  variant="standard"
                                  InputProps={{
                                    ...params.InputProps,
                                    type: "search",
                                    size: "small",
                                  }}
                                />
                              )}
                            />
                          )}
                        />
                        <p style={{ fontSize: "10px", color: "red" }}>
                          {errors.pat_rel?.message}
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          placeholder="Policy #"
                          fullWidth
                          {...register("ins_number")}
                          error={errors.ins_number}
                        />
                        <p style={{ fontSize: "10px", color: "red" }}>
                          {errors.ins_number?.message}
                        </p>
                      </Grid>
                      <Grid item xs={6} style={{ width: "300px" }}>
                        <Typography
                          component="h5"
                          variant="headline"
                          fontWeight="bold"
                        >
                          DOB
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ width: "300px" }}>
                        <Typography
                          component="h5"
                          variant="headline"
                          fontWeight="bold"
                        >
                          DOS
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          type="date"
                          fullWidth
                          margin="dense"
                          {...register("ins_dob")}
                          error={errors.ins_dob}
                        />
                        <p style={{ fontSize: "10px", color: "red" }}>
                          {errors.ins_dob?.message}
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <Input
                          type="date"
                          fullWidth
                          margin="dense"
                          {...register("fdos")}
                          error={errors.fdos}
                        />
                        <p style={{ fontSize: "10px", color: "red" }}>
                          {errors.fdos?.message}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                  <Grid
                    container
                    spacing={1}
                    style={{
                      paddingTop: "20px",
                      paddingLeft: "12px",
                    }}
                  >
                    <Grid item xs={4}>
                      <Button
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        disabled={submitting}
                        color="secondary"
                        size="small"
                        fullWidth
                      >
                        Check Eligibility
                      </Button>
                    </Grid>
                  </Grid>
                  {seterrorReceived && seterrorReceived.length > 0 ? (
                    <Grid item xs={0} sm={3}>
                      <Typography
                        component="label"
                        style={{ fontWeight: 500 }}
                        fontSize="15px"
                        paddingTop="30px"
                        paddingLeft="20px"
                        color="red"
                        // paddingTop="50px"
                      >
                        {/* <PermIdentity style={{ paddingTop: "4px" }} color="dark" /> */}
                        {errorReceived}
                      </Typography>
                    </Grid>
                  ) : null}
                </Box>
              </Container>
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
